/**
  IC LOCAL STYLES are styles that belong to local components which requires
  implementation on a global scope to ensure immutability from overwrites by other styles in the application.

  This stylesheet should be loaded last on the queue of stylesheets.
  **/

/**
  IC View reporting table - for use in the pages/reports/reports-page component.
**/
.ic-view-reporting-table {
  --mat-table-header-headline-color: #0000008a;
  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    padding: 0;
  }
  border: 0 !important;
  border-spacing: 0px !important;
  border-radius: 0px !important;
  overflow: hidden !important;
  border-collapse: collapse !important;
  width: calc(100% - 30px);
  table-layout: fixed;
  &.small-text * {
    font-size: 0.75rem /* 12px */ !important;
    line-height: 1rem /* 16px */ !important;
  }
  &.mat-mdc-table {
    min-width: 0;
    table-layout: fixed;
  }
  tr {
    min-height: auto !important;
    height: 30px !important;
    border-left: 2px solid white !important;
    border-bottom: 1px solid #dddddd !important;
    .mat-mdc-cell {
      text-align: center !important;
      height: 30px !important;
      border-right: solid 1px #ffffff !important;
      justify-content: left !important;
    }
    .mat-mdc-cell:first-of-type {
      width: 20% !important;
      max-width: 20% !important;
      background-color: #ffffff !important;
      text-align: left !important;
      padding-right: 30px !important;
      padding-left: 0.5rem !important;
    }
    .mat-mdc-cell:last-of-type {
      border-right: 0 !important;
      padding-right: 0 !important;
    }
    &:last-of-type {
      margin-top: 20px;
    }
    &.mat-mdc-header-row {
      height: 50px !important;
      background-color: white !important;
      .mat-mdc-header-cell {
        height: 50px;
        font-size: 1rem !important;
        text-align: center;
        border-right: solid 1px #dddddd !important;
        border-bottom: 0 !important;
        &:first-of-type {
          width: 20% !important;
          max-width: 20% !important;
          visibility: hidden !important;
          border-bottom: solid 1px #dddddd !important;
          border-right: 0 !important;
          border: 0;
          padding-left: 30px !important;
          padding-right: 30px !important;
        }
        &:last-of-type {
          border: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
  tr:not(.mat-mdc-header-row):nth-of-type(1) {
    background: #ababab !important;
    .mat-mdc-cell:not(:first-of-type) {
      color: white !important;
    }
    .mat-mdc-cell:first-of-type {
      color: #ababab !important;
    }
  }
  tr:not(.mat-mdc-header-row):nth-of-type(2) {
    background: #7f7f7f !important;
    .mat-mdc-cell:not(:first-of-type) {
      color: white !important;
    }
    .mat-mdc-cell:first-of-type {
      color: #7f7f7f !important;
      border-bottom: solid 1px #dddddd !important;
    }
  }
  tr:not(.mat-mdc-header-row):nth-of-type(3) {
    background: #5fb5cd !important;
    .mat-mdc-cell {
      color: #5fb5cd !important;
    }
    .mat-mdc-cell:not(:first-of-type) {
      color: white !important;
    }
  }
  tr:not(.mat-mdc-header-row):nth-of-type(4) {
    background: #31859c !important;
    .mat-mdc-cell:not(:first-of-type) {
      color: white !important;
    }
    .mat-mdc-cell:first-of-type {
      color: #31859c !important;
      border-bottom: solid 1px #dddddd !important;
    }
  }
  tr:not(.mat-mdc-header-row):nth-last-of-type(2) {
    background: #ffffff !important;
    font-weight: bold;
    .mat-mdc-cell:not(:first-of-type, :last-of-type) {
      color: #333333 !important;
      border-right: solid 1px #dddddd !important;
    }
  }
  tr:not(.mat-mdc-header-row):nth-last-of-type(1) {
    background: #e4e4e4 !important;
    cursor: pointer !important;
    border-bottom: 0 !important;
    .mat-mdc-cell:first-of-type {
      color: #333333 !important;
      border-bottom: 0 !important;
    }
  }
}

.ic-trades-limits-table-header {
  display: flex;
  width: 100%;
  background-color: #d9d9d9;
  font-size: 11px;
  overflow: hidden !important;
  line-height: 14px !important;
}
.ic-trades-limits-table-column {
  font-weight: bold;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-left: 1px solid white;
  border-right: 1px solid white;
  text-transform: capitalize;
}
.ic-trades-limits-bg-even {
  background-color: #eeeeee;
}
.ic-trades-limits-bg-odd {
  background-color: #fafafa;
}
.ic-trades-limits-cell {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-left: 1px solid white;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.ic-trades-limits-row {
  line-height: 15px;
}
.ic-pdf-printable {
  background-color: white;
}

/**
  IC View annual reporting table - for use in the pages/reports/reports-page component.
**/
.ic-view-annual-reporting-table {
  margin-left: 50px;
  border: 0 !important;
  position: relative;
  width: calc(100% - 100px);
  border-spacing: 0px !important;
  border-radius: 0px !important;
  overflow: hidden !important;
  border-collapse: collapse !important;
  line-height: 0 !important;
  table-layout: fixed;
  &.mat-mdc-table {
    min-width: 0;
    table-layout: fixed;
  }
  tr {
    height: 20px !important;
    min-height: 0 !important;
    &.mat-mdc-header-row {
      position: relative !important;
      z-index: 3 !important;
      height: 20px !important;
      min-height: 0 !important;
      background-color: white !important;
      .mat-mdc-header-cell {
        text-transform: capitalize;
        font-size: 0.8rem !important;
        text-align: left;
        border: 0;
        color: black !important;
        padding: 0 !important;
        height: 20px !important;
      }
    }
    .mat-mdc-cell {
      padding-right: 0 !important;
      padding-left: 0 !important;
      border-right: solid 1px #ffffff !important;
      border-bottom: solid 1px #ffffff !important;
      cursor: pointer !important;
      color: white !important;
      text-align: center !important;
      justify-content: center !important;
      font-size: 0.75rem !important;
      height: 20px !important;
    }
  }
  tr:not(.mat-mdc-header-row):nth-of-type(1) {
    background: #ababab !important;
  }
  tr:not(.mat-mdc-header-row):nth-of-type(2) {
    background: #7f7f7f !important;
  }
  tr:not(.mat-mdc-header-row):nth-of-type(3) {
    background: #5fb5cd !important;
  }
  tr:not(.mat-mdc-header-row):nth-of-type(4) {
    background: #31859c !important;
  }
  tr:not(.mat-mdc-header-row):nth-of-type(5) {
    background: #ffffff !important;
    .mat-mdc-cell {
      color: black !important;
      border-left: solid 1px #ddd !important;
      border-right: solid 1px #ddd !important;
      border-bottom: solid 1px #ddd !important;
    }
  }
  tr:not(.mat-mdc-header-row):nth-of-type(6) {
    background: #ffffff !important;
    .mat-mdc-cell {
      color: black !important;
      border-left: solid 1px #ddd !important;
      border-right: solid 1px #ddd !important;
    }
  }
}

/**
  IC View basic reporting table - for use in the pages/reports/reports-page component.
**/
.ic-view-overview-reporting-table {
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-line-height: 12px;
  width: 100%;
  border: 0 !important;
  border-radius: 0px !important;
  border-spacing: 0px !important;
  overflow: hidden !important;
  border-collapse: separate !important;
  color: black !important;
  .mat-mdc-header-row {
    border: 0 !important;
    min-height: 48px !important;
    height: 48px !important;
    background-color: #d9d9d9 !important;
    th {
      min-height: 48px !important;
      text-transform: capitalize;
      color: black;
      height: 20px !important;
      text-align: center !important;
      justify-content: center !important;
      align-items: center !important;
      border-right: solid 1px #ffffff !important;
      border-bottom: solid 1px #ffffff !important;
      padding: 5px 0 !important;
    }
  }
  tr {
    min-height: 25px !important;
    height: 25px !important;
    border: 0 !important;
    .mat-mdc-cell {
      text-align: center !important;
      justify-content: center !important;
      align-items: center !important;
      width: 22.5% !important;
      &:first-of-type {
        width: 30% !important;
        background-color: #d9d9d9;
        text-align: right !important;
      }
      cursor: pointer !important;
      border-right: solid 1px #ffffff !important;
      border-bottom: solid 1px #ffffff !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &:nth-of-type(odd) {
      background-color: #eeeeee;
    }
    &:nth-of-type(even) {
      background-color: #fafafa;
    }
  }
  .mat-mdc-cell {
    justify-content: center !important;
    align-items: center !important;
    min-height: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
    text-align: left;
    border: 0 !important;
  }
}

/**
  IC View limits reporting table - for use in the pages/reports/reports-page component.
**/
.ic-view-last-limits-reporting-table {
  width: 100%;
  border-radius: 0px !important;
  border-spacing: 0px !important;
  overflow: hidden !important;
  border-collapse: separate !important;
  .mat-mdc-header-row {
    min-height: 20px !important;
    height: 20px !important;
    text-align: left !important;
    background-color: #d9d9d9 !important;
    line-height: 0 !important;
    th {
      height: 20px !important;
      min-height: 20px !important;
      text-align: left !important;
      text-transform: capitalize;
      color: black;
      border-right: solid 1px #ffffff !important;
      border-bottom: solid 1px #ffffff !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      &:nth-of-type(2) {
        width: 35%;
        flex: 1 1 35% !important;
      }
      &:nth-of-type(7),
      &:nth-of-type(8) {
        width: 5%;
        flex: 1 1 5% !important;
      }
      &:nth-of-type(6) {
        width: 4%;
        flex: 1 1 5% !important;
      }
    }
  }
  tr {
    line-height: 14px !important;
    min-height: 20px !important;
    height: auto !important;
    border-bottom: solid 1px #ffffff !important;
    .mat-mdc-cell {
      font-size: 11px !important;
      padding: 0 !important;
      border-right: solid 1px #ffffff !important;
      cursor: pointer !important;
      color: black !important;
      justify-content: flex-start !important;
    }
    &:nth-of-type(odd) {
      background-color: #eeeeee;
    }
    &:nth-of-type(even) {
      background-color: #fafafa;
    }
  }
  .mat-mdc-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px !important;
    text-align: left !important;
    &:nth-of-type(2) {
      width: 35%;
      flex: 1 1 35% !important;
    }
    &:nth-of-type(7),
    &:nth-of-type(8) {
      width: 5%;
      flex: 1 1 5% !important;
    }
    &:nth-of-type(6) {
      width: 4%;
      flex: 1 1 5% !important;
    }
    min-height: 20px !important;
    height: auto !important;
    border: 0 !important;
  }
  &.ic-view-annual-overview-table {
    .mat-mdc-header-row {
      th {
        font-size: 11px !important;
        text-align: left !important;
        &:not(:nth-of-type(1)) {
          width: unset !important;
          flex: 1 !important;
        }
        &:nth-of-type(1) {
          width: 50%;
          flex: 1 1 50% !important;
        }
        &:nth-of-type(6),
        &:nth-of-type(7) {
          width: 5%;
          flex: 1 1 5% !important;
        }
      }
    }
    .mat-mdc-cell {
      font-size: 11px !important;
      text-align: left !important;
      &:not(:nth-of-type(1)) {
        width: unset !important;
        flex: 1 !important;
      }
      &:nth-of-type(1) {
        width: 50%;
        flex: 1 1 50% !important;
      }
      &:nth-of-type(6),
      &:nth-of-type(7) {
        width: 5%;
        flex: 1 1 5% !important;
      }
    }
  }
}

/**
  IC View last trades reporting table - for use in the pages/reports/reports-page component.
**/
.ic-view-last-trades-reporting-table {
  width: 100%;
  border-radius: 0 !important;
  border-spacing: 0 !important;
  overflow: hidden !important;
  border-collapse: separate !important;
  .mat-mdc-header-row {
    min-height: 20px !important;
    height: 20px !important;
    text-align: left !important;
    background-color: #d9d9d9 !important;
    line-height: 0 !important;
    th {
      height: 20px !important;
      min-height: 20px !important;
      text-align: left !important;
      text-transform: capitalize;
      color: black;
      border-right: solid 1px #ffffff !important;
      border-bottom: solid 1px #ffffff !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      justify-content: flex-start !important;
      &:nth-of-type(3) {
        width: 40%;
        flex: 1 1 40% !important;
      }
    }
  }
  tr {
    line-height: 14px !important;
    min-height: 20px !important;
    align-items: stretch !important;
    height: auto !important;
    border-bottom: solid 1px #ffffff !important;
    .mat-mdc-cell {
      padding: 0 !important;
      border-right: solid 1px #ffffff !important;
      cursor: pointer !important;
      color: black !important;
    }
    &:nth-of-type(odd) {
      background-color: #eeeeee;
    }
    &:nth-of-type(even) {
      background-color: #fafafa;
    }
  }
  .mat-mdc-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: flex-start !important;
    font-size: 11px !important;
    text-align: left !important;
    &:nth-of-type(3) {
      width: 40%;
      flex: 1 1 40% !important;
    }
    min-height: 20px !important;
    height: auto !important;
    border: 0 !important;
  }
  &.ic-view-annual-overview-table {
    .mat-mdc-header-row {
      th {
        font-size: 11px !important;
        text-align: left !important;
        &:not(:nth-of-type(2)) {
          width: unset !important;
          flex: 0 0 auto !important;
        }
        &:nth-of-type(2) {
          width: 250px;
        }
      }
    }
    .mat-mdc-cell {
      font-size: 11px !important;
      text-align: left !important;
      &:not(:nth-of-type(2)) {
        width: unset !important;
        flex: 0 0 auto !important;
      }
      &:nth-of-type(2) {
        width: 250px;
      }
    }
  }
}

/**
  IC Report Create Dialog Textarea styling override
**/
.ic-report-create-dialog-textarea {
  textarea {
    height: 150px !important;
  }
}

/**
  IC formly tab add-item button customised style
**/
.ic-formly-tabs {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    opacity: 0.6 !important;
    cursor: pointer !important;
    color: rgba(0, 0, 0, 0.87) !important;
    padding: 0 10px !important;
    min-width: 80px !important;
    &[aria-selected='true'] {
      opacity: 1 !important;
    }
    .ic-mat-label-clear-button {
      color: gray !important;
      &:hover {
        color: #59788b !important;
      }
    }
  }
  &.ic-formly-tabs-dynamic-item-add-enabled {
    .mat-mdc-tab-labels {
      padding-right: 100px !important;
      box-sizing: border-box !important;
    }
    .mdc-tab:last-of-type {
      right: 0 !important;
      position: absolute !important;
      background-color: transparent !important;
      height: 30px !important;
      margin-top: 10px !important;
      padding: 0 10px 0 5px !important;
      min-width: auto !important;
      outline: none !important;
      opacity: 1 !important;
      user-select: none !important;
      .mdc-tab__content {
        pointer-events: auto;
        --mat-tab-header-inactive-label-text-color: #59788b;
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    overflow: visible !important;

    .mat-mdc-tab-body {
      overflow: visible !important;
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-body-content {
        overflow: visible !important;
      }
    }
  }
}

/**
  Bar chart section in Reporting/ViewReports total-overview chart component
**/
.ic-report-total-overview-section-chart {
  min-height: 400px;
}

/**
  Reporting Feature - Total Over section vertical labels
**/
.report-branding-logo {
  width: 180px !important;
  height: 60px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.report-section {
  background-color: white !important;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 2px 0px;

  // Enforce PT Family font application so it's retained during report generation.
  font-family: 'pt' !important;
}
.report-glossary-table {
  border-spacing: 0px !important;
  thead,
  tbody {
    td:first-of-type {
      width: 30% !important;
    }
  }
}
.report-section-label-container {
  display: flex;
  width: 30px;
  height: 100%;
}
.report-section-generic-label {
  color: white !important;
  text-align: center !important;
  background-color: #31859c !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'pt' !important;
}
.report-section-overview-label {
  transform: rotate(180deg) !important;
  writing-mode: vertical-rl !important;
  height: 100% !important;
  width: 30px !important;
  color: white !important;
  text-align: center !important;
  background-color: #31859c !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'pt' !important;
}
.report-section-trades-label {
  transform: rotate(180deg) !important;
  writing-mode: vertical-rl !important;
  height: 100% !important;
  width: 30px !important;
  color: white !important;
  text-align: center !important;
  background-color: #31859c !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'pt' !important;
}
.report-section-limits-label {
  transform: rotate(180deg) !important;
  writing-mode: vertical-rl !important;
  height: 100% !important;
  width: 30px !important;
  color: white !important;
  text-align: center !important;
  background-color: #31859c !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'pt' !important;
}
.report-section-overview-price-label {
  transform: rotate(180deg) !important;
  writing-mode: vertical-rl !important;
  width: 30px !important;
  color: white !important;
  text-align: center !important;
  background-color: #31859c !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'pt' !important;
}
.report-section-overview-price-chart-label {
  transform: rotate(180deg) !important;
  writing-mode: vertical-rl !important;
  width: 30px !important;
  color: white !important;
  text-align: center !important;
  background-color: #31859c !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'pt' !important;
}
.report-section-header {
  font-family: 'pt' !important;
}

/**
  Formly drop-list drop-down box style
**/
.ic-drop-down-select-search-input-list {
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

/**
  IC chip - formly drop list chips styling
**/
.ic-drop-down-select-search-input-chip-list {
  .mdc-evolution-chip__text-label,
  .mat-mdc-chip-action-label {
    display: flex;
  }
  .mat-mdc-chip {
    --mdc-chip-elevated-container-color: #7a96a8;
    --mdc-chip-label-text-color: white;
    margin: 2px !important;
    //padding: 3px 7px !important;
    border-radius: 4px !important;
    min-height: 22px !important;
    .mdc-evolution-chip__cell {
      padding: 3px 7px;
      padding-right: 3px !important;
    }

    .mat-mdc-chip-trailing-icon {
      color: white !important;
    }
    .mdc-evolution-chip__cell--trailing {
      padding: 0;
    }
  }
}

/**
  IC Prouct-type Select menu - configure-section product type selection menu style
**/
.ic-report-product-type-select {
  font-family: pt !important;
  &:not(:checked) {
    color: black !important;
  }
}

/**
  IC Reports section list padding
**/
.report-sections-list-container {
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100% !important;
  }
}

/**
  IC Prices list table
  **/

.ic-prices-table {
  border-radius: 0px !important;
  overflow: hidden !important;
  border-collapse: separate !important;
  .mat-mdc-header-row {
    min-height: 35px !important;
    background-color: rgba(149, 173, 187) !important;
    th {
      font-size: 1rem !important;
      color: #fff !important;
    }
  }
  tr:not(.mat-mdc-header-row):hover {
    background: whitesmoke !important;
    cursor: pointer !important;
  }
  .mat-mdc-row:nth-child(2n) {
    background-color: #f6f6f6 !important;
  }
  .mat-mdc-cell {
    border: 0 !important;
  }
}
