@import 'node_modules/tabulator-tables';

//Tabular styles override

.tabulator {
  font-size: 14px;
  background-color: #3d4a4c00;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator
  .tabulator-tableholder
  .tabulator-range-overlay
  .tabulator-range::after {
  width: 0 !important;
  height: 0 !important;
}
.tabulator .tabulator-tableholder .tabulator-range-overlay .tabulator-range {
  border-color: #829eaf !important;
  background-color: #829eaf22;
}
.tabulator-range-cell-active {
  border: 1px solid #829eaf !important;
  background-color: #829eaf33;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 0.5rem 1rem;
  //margin-top: auto;
  margin-bottom: auto;
}

.tabulator-row .tabulator-cell {
  border-right-color: rgba(0, 0, 0, 0.06);
  //border-right: none;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable
  .tabulator-col-title {
  padding-right: 1rem;
}

.tabulator .tabulator-header {
  //font-weight: normal;
  background-color: #f0f0f0;
  border-bottom: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  color: #333333;
}

.tabulator .tabulator-header .tabulator-col {
  min-height: 48px;
  background-color: #f0f0f0;
  border-right-color: rgba(0, 0, 0, 0.06);
  //border: none;
  //font-size: 1rem;
  font-weight: bold;
}

.tabulator-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  //border-bottom-color: rgba(0, 0, 0, 0.12);
  //min-height: 48px;
  vertical-align: middle;
}

.tabulator-row:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

div.tabulator-row.tabulator-selectable:not(
    .tabulator-row.tabulator-selected,
    .isEditable
  ):hover
  div.tabulator-cell {
  background-color: #f6f6f6;
  cursor: pointer;
}

div.tabulator-row.isEditable:hover {
  background-color: #c50722;
}
div.tabulator-row.tabulator-row.tabulator-selected:hover {
  background-color: #f0f0f0;
}

.tabulator-row .tabulator-cell {
  padding: 1rem;
  //border-right: none;
  vertical-align: top;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  padding: 1rem;
  color: #000;
  background-color: white;
}

// .tabulator-row .tabulator-cell:first-of-type,
// .tabulator .tabulator-header .tabulator-col:first-of-type {
//   padding-left: 1rem;
// }

.tabulator-row.tabulator-row-even:not(
    .tabulator-row.tabulator-selected,
    .isEditable,
    .tabulator-group
  ) {
  background-color: #fff;
}

.tabulator-row.tabulator-selected {
  background-color: #f0f0f0;
}

.tabulator-row .tabulator-responsive-collapse {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0.5rem 1rem;
}

.tabulator-row.tabulator-selectable:not(
    .tabulator-row.tabulator-selected,
    .isEditable
  ):hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-right {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-left {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  border-bottom: 1px solid #999;
  border-right: 1px solid #aaa;
  border-top: 1px solid #999;
  padding: 5px;
  padding-left: 1rem;
  background: #fff;
  font-weight: bold;
  min-width: 100%;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-sorter {
  right: 0;
}

.tabulator-row.tabulator-selected {
  background-color: #f0f0f0;
}

.tabulator-row .tabulator-responsive-collapse {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0.5rem 1rem;
}

.tabulator-row:not(.tabulator-row.tabulator-selected, .isEditable):hover {
  background-color: #f6f6f6;
  cursor: pointer;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-right {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-left {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  border-bottom: 1px solid #999;
  border-right: 1px solid #aaa;
  border-top: 1px solid #999;
  padding: 5px;
  padding-left: 1rem;
  background: #fff;
  font-weight: bold;
  min-width: 100%;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-sorter {
  right: 0;
}
