/** 
 * Provide base tailwindcss styles and setup
 * themeing and extension hooks for the packages
 */

// This injects Tailwind's base styles and any base styles registered by
// plugins.
@tailwind base;

// This injects Tailwind's component classes and any component classes
// registered by plugins.
@tailwind components;

// This injects Tailwind's utility classes and any utility classes registered
//  by plugins.
@tailwind utilities;

// Use this directive to control where Tailwind injects the hover, focus,
// responsive, dark mode, and other variants of each class.
// If omitted, Tailwind will append these classes to the very end of
// your stylesheet by default.
@tailwind variants;

// Define custom styles for tailwindcss.
// Use @apply to extract and combine tailwindcss classes
// https://tailwindcss.com/docs/reusing-styles
@layer components {
  // .btn-test {
  //   @apply inline-block bg-red-500 rounded-sm font-serif
  // }
}
