/**
Global overrides for material variables
 */
html {
  /* mat form fields */
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.1);
  /* mat card */
  --mat-card-title-text-weight: 500;
  --mat-card-title-text-size: 24px;
  --mdc-outlined-card-outline-width: 0;
  --mdc-outlined-card-container-shape: 0;
  --mdc-outlined-card-container-elevation: none;
  /* mat tab */
  --mat-tab-header-divider-height: 1px;
  --mat-tab-header-divider-color: rgba(0, 0, 0, 0.1);

  /* mat select */
  --mat-select-trigger-text-line-height: 20px;
  /* mat dialog */
  --mdc-dialog-container-shape: 0;
  /* mat list */
  --mdc-list-list-item-selected-container-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-tab-body-wrapper {
  height: 100%;
}
.mat-mdc-list-item {
  padding-left: 4px !important;
  padding-right: 0 !important;
}
.mdc-list-item__primary-text {
  padding-bottom: 2px !important;
}
.mdc-list-item--selected {
  color: black !important;
}

/**
  Mat-select override remove the default select arrow - replaced with a custom arrow implemented
**/

.mat-mdc-select-arrow-wrapper {
  display: none !important;
}

/**
  Mat-datepicker-toggle override - change the colour of the icon
**/
.mat-datepicker-toggle {
  color: #829eaf;
}

/**
  Side navigation override - remove the added border
**/
.mat-drawer-side {
  border: 0 !important;
}

/**
  Side navigation override during "over mode" - for mobile view - maximum width: 85%, with an adjusted shadow contrast (less - to give the main content in the background more visibility)
**/
.mat-drawer:not(.mat-drawer-side) {
  width: 85% !important;
  box-shadow:
    0px 2px 5px -5px rgba(0, 0, 0, 0.2),
    0px 8px 16px 1px rgba(0, 0, 0, 0.05),
    0px 6px 30px 1px rgba(0, 0, 0, 0.12) !important;
}

/**
  Mat-table overrides - to apply corporate choice of table design
**/
table {
  thead {
    .mat-mdc-header-row {
      height: 35px !important;
      .mat-mdc-header-cell:not(:last-of-type):not(:first-of-type) {
        text-align: center;
      }
      .mat-mdc-header-cell:last-of-type {
        text-align: right;
      }
      .mat-mdc-header-cell:first-of-type {
        text-align: left;
      }
    }
  }
  tbody {
    .mat-mdc-row {
      .mat-mdc-cell:not(:last-of-type):not(:first-of-type) {
        text-align: center;
      }
      .mat-mdc-cell:last-of-type {
        text-align: right;
      }
      .mat-mdc-cell:first-of-type {
        text-align: left;
      }
    }
  }

  .mat-sort-header-container:not(.mat-sort-header-sorted)
    .mat-sort-header-arrow {
    display: none;
  }

  /**
    Nested table styling override
  **/
  table {
    border-top: solid 1px #ddd;
    border-bottom: solid 1px #ddd;
  }
}

/**
  Mat-sidenav-content override - remove the default material colour and apply the white canvas background.
**/
.mat-sidenav-content {
  display: flex !important;
  flex-direction: column;
  background-color: #ffffff;
}

/**
  Mat-card override override - remove the box-shadows as per corporate design choice
**/
.mat-mdc-card {
  padding: 1rem;
}
.mat-mdc-card-title {
  padding-bottom: 0.5rem;
}
.mat-mdc-card-subtitle {
  padding-bottom: 1rem;
}
.mat-mdc-card-content {
  padding: 0 !important;
}

/**
  Mat-expansion-panel override - remove the box-shadow as per corporate design choice
**/
.mat-expansion-panel {
  .mat-expansion-indicator {
    line-height: 0 !important;
  }
  .mat-expansion-indicator:after {
    border-color: #829eaf;
    color: #829eaf;
  }
  box-shadow: none !important;
  /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  mat-card {
    box-shadow: none !important;
  }
  mat-expansion-panel {
    box-shadow: none !important;
  }
}

/**
  Mat-select-panel override - adjust the border radius to match the specification. And adjust the positioning to be at the bottom of the parent input.
**/
.mat-mdc-select-panel {
  border-radius: 0 !important;
  //margin-top: 35px !important;
  //min-width: calc(100% + 24px) !important;
  //margin-left: 4px !important;
  // &[aria-multiselectable='true'] {
  //   margin-left: 28px !important;
  // }
}

/**
  Different tiers of icon sizes - small, medium (default), large
**/
.mat-icon {
  font-family: 'Material Icons' !important;
}
.mat-icon.small-icon {
  width: 18px !important;
  height: 18px !important;
  font-size: 18px !important;
}

.mat-icon.large-icon {
  width: 48px !important;
  height: 48px !important;
  font-size: 48px !important;
}

.mat-icon.disabled {
  opacity: 0.3;
  pointer-events: none;
}

/**
  Mat-icon-button override - set the line-height to 1 to center the material icons vertically.
**/
.mat-mdc-icon-button {
  line-height: 1 !important;
}

/**
  Mat-form-field inputs override - remove the border radius of the input as per corporate design choice
**/
.mat-mdc-form-field-infix {
  margin-top: 4px;
}
.mat-mdc-text-field-wrapper {
  margin-top: 4px;
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 0 !important;
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0 !important;
}
.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-text-field-wrapper {
  background-color: white;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #e5e7eb;
}
.mdc-text-field--disabled {
    background: rgba(0, 0, 0, 0.05) !important;
}

/**
  Mat-mini-fab - override mat-fab line-height to 0 to align the contents to middle and change the box-shadow to a less harsher tone
**/
.mat-mdc-fab,
.mat-mdc-mini-fab {
  line-height: 0 !important;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}

/**
  CDK Overlay backdrop override - increase the darkness of the backdrop to allow the user to focus on dialogs
**/
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
}

/**
  Mat-menu override - remove border radius and padding to remain consistent with design specification.
**/
.mat-mdc-menu-panel {
  border-radius: 0 !important;
  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}
/**
* Stepper
*/
.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused {
  background-color: transparent !important;
}
.mat-step-header:hover:not(
    [aria-disabled],
    [aria-selected='true'],
    .mat-vertical-stepper-header
  ) {
  transform: scale(0.9);
}
.mat-vertical-stepper-header:hover:not(
    [aria-disabled],
    [aria-selected='true']
  ) {
  .mat-step-label {
    transform: scale(0.9);
    transition: all 0.1s ease-in-out;
  }
}

.mat-step-header[aria-selected='true'] {
  cursor: default;
}
.mat-step-header {
  transition: all 0.1s ease-in-out;
}
.mat-horizontal-stepper-header {
  padding: 0 !important;
}
.mat-horizontal-content-container {
  padding: 0 !important;
}
.mat-vertical-stepper-header {
  padding: 1rem 0 !important;
}
.mat-vertical-content-container {
  margin-left: 0.75rem !important;
}
