// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';
// Plus imports for other components in your app.

/**
  Material icons configuration - load from assets instead of relying on remote link from Google Fonts
  Note: static font sourcing from the assets folder is required for the PDF Report generation; where fonts are loaded and converted to blobs for SVG Injection
**/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/material.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$ic_primary: (
  50: #f8e1e4,
  100: #eeb5bd,
  200: #e28391,
  300: #d65164,
  400: #ce2c43,
  500: #c50722,
  600: #bf061e,
  700: #b80519,
  800: #b00414,
  900: #a3020c,
  A100: #ffcecf,
  A200: #ff9b9d,
  A400: #ff686c,
  A700: #ff4e53,
  contrast: (
    50: rgba(#000, 0.87),
    100: rgba(#000, 0.87),
    200: rgba(#000, 0.87),
    300: rgba(#000, 0.87),
    400: rgba(#000, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(#000, 0.87),
    A200: rgba(#000, 0.87),
    A400: rgba(#000, 0.87),
    A700: white,
  ),
);

$ic_accent: (
  50: #f0f3f5,
  100: #dae2e7,
  200: #c1cfd7,
  300: #a8bbc7,
  400: #95adbb,
  500: #829eaf,
  600: #7a96a8,
  700: #6f8c9f,
  800: #658296,
  900: #527086,
  A100: #ebf6ff,
  A200: #b8e1ff,
  A400: #85cbff,
  A700: #6bc0ff,
  contrast: (
    50: rgba(#000, 0.87),
    100: rgba(#000, 0.87),
    200: rgba(#000, 0.87),
    300: rgba(#000, 0.87),
    400: rgba(#000, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(#000, 0.87),
    A200: rgba(#000, 0.87),
    A400: rgba(#000, 0.87),
    A700: white,
  ),
);

$ic-portal-primary: mat.m2-define-palette($ic_primary);
$ic-portal-accent: mat.m2-define-palette($ic_accent);
$ic-portal-typography: mat.m2-define-typography-config(
  $font-family: "'pt'",
  $button:
    mat.m2-define-typography-level(
      $font-family: "'pt'",
      $font-size: 14px,
      $letter-spacing: normal,
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-size: 16px,
      $letter-spacing: normal,
    ),
);

$ic-portal-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $ic-portal-primary,
      accent: $ic-portal-accent,
    ),
    typography: $ic-portal-typography,
    density: -3,
  )
);

@include mat.all-component-themes($ic-portal-theme);
@include mat.button-density(0);
@include mat.list-density(0);
@include mat.tabs-density(0);
@include mat.toolbar-density(0);
