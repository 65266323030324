@font-face {
  font-family: 'pt';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/pt.ttf) format('truetype');
}

@font-face {
  font-family: 'pt-bold';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/pt-bold.ttf) format('truetype');
}

@font-face {
  font-family: 'pt-italic';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/pt-italic.ttf) format('truetype');
}
.ic-multiline-tooltip {
  white-space: pre-line;
  max-width: unset !important;
}
.ic-default-font {
  font-family: 'pt' !important;
}
.ic-default-font-bold {
  font-family: 'pt-bold' !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #9aaebb;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #9aaebb;
}
* {
  scrollbar-color: #9aaebb #ffffff !important;
  scrollbar-width: thin !important;
}
.ic-disable-content-on-submit {
  opacity: 0.4;
  pointer-events: none;
}
.ic-hide-and-disable-content {
  opacity: 0;
  pointer-events: none;
}
.text-2xs {
  font-size: 0.675rem;
  line-height: 0.875rem;
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: 'pt';
  font-weight: normal;
  background-color: #ffffff;
  & > img {
    display: none;
  }
}

/**
  ensure that the input elements including button, select, are rounded properly
**/
button,
input,
select {
  border-radius: 0px !important;
}

main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999; /* Ensure that your app's content doesn't overlap the toolbar */
  transition: all 0.3s ease-in-out;
}

a {
  text-decoration: none;
  color: #829eaf;
}

p {
  text-align: justify;
}

b {
  font-family: 'pt-bold';
  font-weight: normal !important;
}

i {
  font-family: 'pt-italic';
  font-weight: normal !important;
}

input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.current-route {
  background: rgba(0, 0, 0, 0.08);
}

.mat-mdc-tooltip {
  font-size: 1rem;
}

.success-snackbar {
  --mdc-snackbar-container-color: green;
  --mdc-snackbar-supporting-text-color: white;
  button,
  span {
    color: white;
  }
}

.warn-snackbar {
  --mdc-snackbar-container-color: #d4a83d;
  --mdc-snackbar-supporting-text-color: white;
  button,
  span {
    color: white;
  }
}

.border-for-overflow-tables {
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.error-snackbar {
  --mdc-snackbar-container-color: #f44336;
  --mdc-snackbar-supporting-text-color: white;
  button,
  span {
    color: white;
  }
}
.ic-blue-bg {
  background: url('./assets/inercomp/blau-wl-hg.png');
}

/**
  Mat-button override - remove the min-width and set to auto
**/
.ic-floating-button {
  min-width: auto !important;
  padding: 0;
}

/**
  IC Mat Table overrides default material table and applied all over the platform.
**/
.ic-expandable-mat-table {
  border-radius: 0px !important;
  border-collapse: separate !important;
  .mat-mdc-header-row {
    background-color: rgba(149, 173, 187) !important;
    th {
      font-size: 1rem !important;
      color: #fff !important;
    }
  }
  tr:not(.mat-mdc-header-row, .expanded-row):hover {
    background: #e9e9e9 !important;
    cursor: pointer !important;
  }
  .expandable-row td {
    border-bottom-width: 0 !important;
  }
  .expandable-row:nth-child(4n + 3) {
    background-color: #f0f0f0 !important;
  }
  .expanded-row {
    height: 0;
    td {
      border-bottom-width: 0 !important;
    }
    &:hover {
      cursor: default !important;
      background: none !important;
    }
  }
  .opened-expanded-row {
    background-color: rgba(130, 158, 175, 0.125) !important;
    font-weight: 700 !important;
  }
  .expanded-row-content {
    overflow: hidden !important;
    display: flex !important;
  }
  .sub-table {
    padding: 0 !important;
  }
}

.ic-mat-table {
  border-radius: 0px !important;
  border-collapse: separate !important;
  .mat-mdc-header-row {
    min-height: 35px !important;
    background-color: rgba(149, 173, 187) !important;
    th {
      font-size: 1rem !important;
      color: #fff !important;
    }
  }
  tr:not(.mat-mdc-header-row):hover {
    background: whitesmoke !important;
    cursor: pointer !important;
  }
  .mat-mdc-row:nth-child(2n) {
    background-color: #f6f6f6 !important;
  }
  .mat-mdc-cell {
    border: 0 !important;
  }
}

.ic-expandable-mat-table {
  thead {
    .mat-mdc-header-row {
      height: 35px !important;
      min-height: 35px !important;
      .mat-mdc-header-cell:not(:last-of-type):not(:first-of-type) {
        text-align: center !important;
      }
      .mat-mdc-header-cell:last-of-type {
        text-align: right !important;
      }
      .mat-mdc-header-cell:first-of-type {
        text-align: left !important;
      }
    }
  }
  tbody {
    .mat-mdc-row {
      .mat-mdc-cell:not(:last-of-type):not(:first-of-type) {
        text-align: center !important;
      }
      .mat-mdc-cell:last-of-type {
        text-align: right !important;
      }
      .mat-mdc-cell:first-of-type {
        text-align: left !important;
      }
    }
  }

  /**
    Nested table styling override
  **/
  table {
    border-top: solid 1px #ddd !important;
    border-bottom: solid 1px #ddd !important;
  }
}

.mobile-label {
  display: none;
}

.ic-mobile-table {
  .mat-mdc-table {
    border: none;
  }
  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 0.5rem;
  }
  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 0.5rem;
  }
  .mobile-label {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
  }
  .mat-mdc-header-row {
    display: none;
  }
  .mat-mdc-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  .mat-mdc-cell {
    width: 100%;
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
  }
}

/**
  IC mat-xpandables override - make sure that the elements tagged with this class cannot emit the evenets - the goal is to override the expansion and collapse of certain accordions in the content
**/
.ic-header-cannot-expand {
  pointer-events: none;
  .mat-expansion-indicator {
    display: none !important;
  }
}

/**
  IC Large Dialog Sizing class for dialogs that require more information to be displayed in the screen
**/
.ic-large-dialog {
  min-width: 80vw !important;
  max-width: 80vw !important;
  .mat-mdc-dialog-container {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    .mat-mdc-dialog-title {
      margin: 0 !important;
    }
  }
  &:not(.ic-dialog-fluid-height) {
    min-height: 95vh !important;
    max-height: 95vh !important;
  }
  &.ic-dialog-fluid-height {
    min-height: auto !important;
    max-height: 95vh !important;
  }
}
@media only screen and (max-width: 1000px) {
  .ic-large-dialog {
    flex-direction: column !important;
    padding: 10px;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    .mat-mdc-dialog-container {
      flex: 1 !important;
    }
  }
}

/**
  IC Medium Dialog Sizing class for dialogs that require more information to be displayed in the screen
**/
.ic-medium-dialog {
  width: 50vw !important;
  max-width: 800px !important;
  .mat-mdc-dialog-container {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    .mat-mdc-dialog-title {
      margin: 0 !important;
    }
  }
  &:not(.ic-dialog-fluid-height) {
    min-height: 45vh !important;
    max-height: 45vh !important;
  }
  &.ic-dialog-fixed-height {
    min-height: 95vh !important;
    max-height: 95vh !important;
  }
  &.ic-dialog-fluid-height {
    min-height: auto !important;
    max-height: 95vh !important;
  }
}
@media only screen and (max-width: 1000px) {
  .ic-medium-dialog {
    flex-direction: column !important;
    padding: 10px;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    .mat-mdc-dialog-container {
      flex: 1 !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .ic-medium-dialog {
    flex-direction: column !important;
    padding: 10px;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    .mat-mdc-dialog-container {
      flex: 1 !important;
    }
  }
}

/**
  IC Small Dialog Sizing class for dialogs that require more information to be displayed in the screen
**/
.ic-small-dialog {
  width: 30vw !important;
  max-width: 500px !important;
  .mat-mdc-dialog-container {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    .mat-mdc-dialog-title {
      margin: 0 !important;
    }
  }
  &:not(.ic-dialog-fluid-height) {
    min-height: 45vh !important;
    max-height: 45vh !important;
  }
  &.ic-dialog-fluid-height {
    min-height: auto !important;
    max-height: 95vh !important;
  }
}
@media only screen and (max-width: 1000px) {
  .ic-small-dialog {
    flex-direction: column !important;
    padding: 10px;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    .mat-mdc-dialog-container {
      flex: 1 !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .ic-small-dialog {
    flex-direction: column !important;
    padding: 10px;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    .mat-mdc-dialog-container {
      flex: 1 !important;
    }
  }
}

/**
  IC Form Dialog Sizing class for dialogs that show the form in the modal window
**/
.ic-form-dialog {
  width: 56rem;
  max-width: 56rem !important;
  .mat-mdc-dialog-container {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    .mat-mdc-dialog-title {
      margin: 0 !important;
    }
  }
  &:not(.ic-dialog-fluid-height) {
    min-height: 95vh !important;
    max-height: 95vh !important;
  }
  &.ic-dialog-fluid-height {
    min-height: auto !important;
    max-height: 95vh !important;
  }
}
@media only screen and (max-width: 56rem) {
  .ic-form-dialog {
    flex-direction: column !important;
    padding: 10px;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    .mat-mdc-dialog-container {
      flex: 1 !important;
    }
  }
}

/**
  IC primary-color mat-button-toggle-group - for light-background pages
**/
.ic-primary-button-toggle {
  .mat-button-toggle-appearance-standard {
    background-color: #59788b !important;
    color: white !important;
  }
  &.mat-button-toggle-group-appearance-standard {
    border: none !important;
  }
}

/**
  IC dialog-panel override - get rid of additional background for the mat-container and reduce the size to a reasonable 60vw;
**/
.ic-dialog-panel {
  max-width: 50vw;
  .mat-mdc-dialog-container {
    padding: 0 !important;
    background-color: transparent !important;
    overflow: hidden;
  }
}

/**
  IC Mat-list - without padding in the contents
**/
.ic-list {
  padding: 0 !important;
  .mdc-list-item {
    height: auto !important;
    .mdc-list-item__content {
      padding: 0 !important;
    }
  }
  &.ic-list-bordered {
    .mdc-list-item {
      border-bottom: solid 1px #dddddd;
    }
  }
}

/**
  IC mat-progress-bar (indeterminate  - should float therefore adjust the bottom margin to give back the height taken by the progress-bar
**/
.ic-progress-bar-indeterminate {
  margin-bottom: -4px;
  &.ic-progress-bar-indeterminate-sticky {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
  }
}

/**
  IC mat-divider - color changed to cecece consistent with other prominent borders in the pages
**/
.ic-divider {
  border-top-color: #dddddd !important;
}

/**
  IC link - force change color change linked types text to primary color
**/
.ic-link {
  color: #829eaf;
  cursor: pointer;
}

/**
  IC file input container - customised input field for formly file picker
**/
.ic-file-input-container {
  position: relative;
  top: -10px;
}

/**
  This can be used on any text element where caution is required
**/
.ic-danger {
  color: red;
}

/**
  Prop class to indicate a prop - for testing only
**/
.prop {
  border: solid 1px red;
}

/**
  Reset styles of an element - including tailwind for clean styling useful on specific elements
**/
.clean {
  all: unset;
}

/**
  Drop down search with filter for custom formly wrapper
**/
.ic-drop-list {
  position: relative;
  width: inherit;
  height: 255px;
  z-index: 100000;
  padding-top: 0 !important;
  background: white;
  overflow-y: auto;
  .mat-mdc-list-item {
    /*TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-list-item-content {
      padding: 5px !important;
      padding-right: 16px !important;
    }
  }
}

/**
  Table usable background color classes
**/
.ic-table-odd-bg {
  background-color: #eeeeee;
}
.ic-table-even-bg {
  background-color: #fafafa;
}

/**
    Generic suspended component class
**/
.ic-suspended {
  opacity: 0.4;
  pointer-events: none;
}

@-webkit-keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}

@keyframes fade {
  100% {
    background-color: #fff;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
