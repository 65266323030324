
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
formly-field-mat-radio {
  .mat-mdc-radio-group {
    display: inline-flex !important;
    flex-direction: column !important;
  }

  .mat-mdc-radio-button {
    margin: 0.5rem 0 !important;
  }
}
